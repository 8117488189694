@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// themes - our custom or/and out of the box themes
@import 'themes';
// framework component themes (styles tied to theme variables)
//@import '~@nebular/theme/styles/globals';
//@import '~@nebular/auth/styles/all';
@import '~@nebular/theme/styles/prebuilt/corporate';
@import '~@nebular/auth/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/default';
// install the framework and custom global styles
@include nb-install() {

	// framework global styles
	@include nb-theme-global();
	@include nb-auth-global();

	@include ngx-layout();
	// loading progress bar
	@include ngx-pace-theme();

	@include nb-overrides();

	::ng-deep .step-content {
		width: 100% !important;
	}
}

label.required::after {
	content: '*';
	color: red;
}

.cursor {
	cursor: pointer;
}

.nb-theme-corporate nb-sidebar .main-container {
	background: transparent;
}

.nb-theme-corporate nb-menu {
	background: transparent;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(1) a nb-icon {
	color: #59a1ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(2) a nb-icon {
	color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(3) a nb-icon {
	color: #ff2db5 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(4) a nb-icon {
	color: #00adc3 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(5) a nb-icon {
	color: #9cd318 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(6) a nb-icon {
	color: #7659ff !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(7) a nb-icon {
	color: #f45b2c !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(8) a nb-icon {
	color: #9c56c4 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(9) a nb-icon {
	color: #0F9B8E !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(10) a nb-icon {
	color: #133374 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(11) a nb-icon {
	color: #C9B003!important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(12) a nb-icon {
	color: #4A0100 !important;
}
.nb-theme-corporate nb-menu .menu-item:nth-child(13) a nb-icon {
	color: #2DFE54 !important;
}

.nb-theme-corporate nb-menu .menu-item:nth-child(14) a nb-icon {
	color: #FD4659 !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item {
	border-top: none;
	font-weight: bold;
	border-radius: 0;
	color: #505a64;
	border-bottom: none !important;
}

.nb-theme-corporate .menu-sidebar .scrollable {
	padding-top: 0 !important;
	background-color: #ffffff;
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3) !important
}

.menu-sidebar .menu-item a .menu-title {
	color: #fff;
}
.table-bordered td {
	border: 1px solid #cdd2d5;
}

.nb-theme-corporate nb-card {
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
	background-color: #ffffff;
	color: #505a64; border-radius:10px;
}

.nb-theme-corporate .header-container .navigation {
	font-size: 3.0rem;
}

.menu-sidebar.expanded .menu-item a.active {
	border-right: 8px solid #59a1ff;

}

.nb-theme-corporate nb-layout .layout nb-layout-header nav {
	//background: #f8f8fb;
	//background: #f8fbff;
	background: #ffffff;
	//background: #ebf5ff;
	color: #000;
}
.nb-theme-corporate nb-layout .layout {
	//background-color: #f8fbff;
	//background: #ebf5ff;
	//background: #eaeaea;
	background: #f1f5f8!important;
}

.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
	background-color: rgba(152, 17, 206, 0.6);
}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
}

.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
}

.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
}

.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}


.nb-theme-corporate nb-menu .menu-item a {
	//color: #1a2138;
	border-radius: 0;
	color: #505a64;
	border-bottom: none !important;
}

.nb-theme-corporate nb-menu .menu-items > .menu-item{
	border-bottom: transparent;
}
.menu-sidebar .menu-item a .menu-title {
	color: #505a64;
}





.marker-cluster span {
	line-height: 30px;
	color: #000;
}

.notifications {
	left: -100% !important;
}

.nb-theme-corporate nb-user.shape-round .user-picture {
	background: #ffffff;
	border: solid 2px #bcc3cc;
}

.nb-theme-corporate nb-checkbox .custom-checkbox {
	border-color: #c0c0c0;
}

.nb-theme-corporate [nbInput] {
	// padding: 0.75rem 2rem;
}

.pac-container {
	z-index: 9999;
}

.nb-theme-corporate nb-stepper .header .label-index {
	width: 1.3rem;
	height: 1.3rem;
}

::ng-deep {
	.viewer-toolbar > ul > li::before {
		margin-top: -14px !important;
		margin-left: 2px !important;
	}
}

a:hover {
	color: #0056b3;
	text-decoration: none !important;
}

.mck-sidebox-launcher {
	bottom: 55px !important;
}

.nb-theme-corporate nb-select.size-giant:not(.full-width), .nb-theme-corporate .options-list:not(.full-width) {
	max-width: 100%;
}

.nb-theme-corporate [nbInput].size-medium:not(.input-full-width) {
	// max-width: inherit;
}

.angular-editor-toolbar {
	display: none;
}

::ng-deep {
.icons-rupee{
	content: "\20B9";
	color: black !important;
}
}

.ng-dropdown-panel.ng-select-bottom {
	z-index: 99999 !important;
}
.legend-wrap {
	min-width: fit-content !important;
}

.marker-pin {
	width: 30px;
	height: 30px;
	border-radius: 50% 50% 50% 0;
	background: #c30b82;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: 50%;
	margin: -15px 0 0 -15px;
}
// to draw white circle
.marker-pin::after {
	content: '';
	width: 24px;
	height: 24px;
	margin: 3px 0 0 3px;
	background: #fff;
	position: absolute;
	border-radius: 50%;
}

// to align icon
.custom-div-icon i {
	position: absolute;
	width: 22px;
	font-size: 14px;
	left: 0;
	right: 0;
	margin: 10px auto;
	text-align: center;
}


.p-datatable-wrapper {
	overflow-x: auto !important;
}
.m-wid-h {width:100%;}

